.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: 1260px;
  padding: 0 30px;
  @media (max-width: 1000px) {
    padding: 0 20px;
  }
  @media (max-width: 572px) {
    padding: 0 18px;
  }
}

/// footer form validation  style
.geekmarkNorm {
  border: 1px solid #fff;
}
.geekmarkErr {
  border: 1px solid red;
}

.footerCheckboxErr {
  color: red;
}
.footerCheckboxNorm {
  color: #fff;
}

.inputFooterError {
  border: 1px solid red;

  &::placeholder {
    color: red;
  }
}

.inputFooterNormal {
  border: 1px solid #ffffff;
  &::placeholder {
    color: #ffffff;
  }
}

.inputError {
  border: 0.5px solid red;

  &::placeholder {
    color: red;
  }
}

.inputNormal {
  border: 0.5px solid #2f2a27;
  &::placeholder {
    color: #817d74;
  }
}

// Rewiew section Splide arrows style

.splide__arrows {
  position: relative;
}

.splide__arrows .splide__arrow {
  position: absolute;
  top: 78px;

  background: unset;
  padding: 0;
  z-index: 1;
}

.splide {
  position: static !important;
}
.splide-list {
  backface-visibility: unset !important;
}

.splide__slide {
  margin: 0 16px 0 0 !important;
}

.custom-wrapper {
  margin-left: 35px;
  @media (max-width: 674px) {
    margin-left: 0px;
  }
}
.splide__arrow--prev {
  left: -32px !important;
  @media (max-width: 674px) {
    left: 0 !important;
  }
}
.splide__arrow--next {
  right: -18px !important;
}

// FAQ icon click
div .faq__iconWrap_click {
  background-color: #000;
  transition: background-color 0.05s ease-in-out;

  svg {
    fill: #ffffff;
    transform: rotate(45deg);
    transition: transform 0.05s ease-in-out;
  }
}

/// Hide text animation

.hide-text {
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  transition: opacity 0.3s ease-in-out;
}

.show-text {
  transform: translateY(0%);
  top: 0;
  transition: opacity 0.3s ease-in-out;
}
.arrow_up {
  fill: #ffffff;

  transform: rotateX(360deg);
  transition: transform 0.03s ease-in-out;
}
.arrow_down {
  transform: rotateX(180deg);
  transition: transform 0.03s ease-in-out;
}

.hide-list {
  display: none;

  transition: display 0.3s ease-in-out;
}

.show-list {
  display: block;

  transition: display 0.3s ease-in-out;
}

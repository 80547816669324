.header__section {
  position: fixed;
  width: 100%;
  z-index: 500;
  padding-top: 40px;
  padding-bottom: 20px;
  background-color: var(--main-bgc);

  border-bottom: 1px solid #bdbdbd;

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__desc {
      font-family: var(
        --secondary-font-family
      );
      font-weight: 300;
      letter-spacing: 0.08em;

      color: var(--accent-color);
    }
  }

  @media (max-width: 1000px) {
    padding-top: 16px;
    // padding-bottom: 5px;
  }

  @media (max-width: 570px) {
    // padding: 11px 0 6px;
    padding-top: 11px;

    .logo {
      &__img {
        width: 100px;
        height: 30px;
      }

      &__desc {
        font-size: 11px;
      }
    }
  }
}

.menu {
  display: block;

  &__list {
    display: flex;
    align-items: center;

    list-style: none;
  }

  &__item {
    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      .menu__link_line {
        display: block;
        border: 2px solid
          var(--accent-color);
      }
    }
  }

  &__link {
    font-size: 20px;
    line-height: 1.15;

    color: var(--main-text-color);
  }

  &__link_line {
    display: block;
    margin-top: 8px;
    border: 2px solid var(--main-bgc);
  }

  @media (max-width: 1000px) {
    display: none;
  }
}
.request__btn {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  padding: 17.5px 26.5px;
  // margin-left: 160px;
  border-radius: 8px;

  color: var(--main-text-color);
  background-color: var(--btn-bgc);

  &:active {
    background-color: #5b3bdd;
    transform: translateY(2px);
  }

  &:hover,
  &:focus {
    background-color: var(
      --hover-color
    );
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.hamburger {
  &__wrap {
    display: none;
  }

  @media (max-width: 1000px) {
    &__wrap {
      display: block;
      margin-bottom: 10px;
    }

    &__list_style {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;

      position: absolute;
      top: 79px;
      left: 0;
      z-index: 200;

      height: 100vh;
      width: 100vw;

      background-color: var(
        --accent-color
      );
    }

    &__list_close {
      display: none;
    }

    &__list_open {
      display: block;
    }

    &__item {
      border-bottom: 2px solid
        var(--main-bgc);
      padding: 15px 0;
      text-align: center;
      width: 100%;

      &:hover,
      &:focus {
        cursor: pointer;
        background-color: #01c285;
      }
    }

    &__link {
      font-size: 16px;
      color: var(--main-text-color);
    }
  }

  @media (max-width: 570px) {
    &__list_style {
      top: 74px;
    }

    &__link {
      font-size: 14px;
    }

    &__item {
      padding: 10px 0;
    }
  }
}

// .header__promotion {
//   text-align: center;
//   background-color: #fe704c;
//   padding: 16px 10px;
//   margin-top: 20px;
// }

// .header__promotionText {
//   font-weight: 700;
//   font-size: 20px;
//   line-height: 2;
// }

// @media (max-width: 730px) {
//   .header__promotionText {
//     font-size: 16px;
//   }
// }

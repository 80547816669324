.google {
  padding: 30px 0 80px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 100px;
    border-radius: 15px;
    background-color: rgba(
      230,
      240,
      255,
      1
    );
  }

  &__img {
    display: block;
    width: 333px;
    height: 104px;
  }

  &__secondary_wrap {
    max-width: 600px;
  }

  &__text {
    font-size: 22px;
    line-height: 30px;
    color: rgba(33, 33, 33, 1);
  }

  &__text_bold {
    margin: 0 2px;
    font-weight: 700;
    font-size: 22px;
    color: rgba(33, 33, 33, 1);
  }

  &__text_blue {
    margin-right: 2px;
    font-weight: 700;
    font-size: 22px;
    color: rgba(79, 134, 238, 1);
  }

  @media (max-width: 1200px) {
    &__wrapper {
      flex-direction: column-reverse;
      padding: 40px 20px;
    }

    &__img {
      margin-bottom: 25px;
    }
  }
}

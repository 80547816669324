.products {
  &__section {
    padding: 40px 0;
  }

  &__header {
    // text-align: start;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;

    margin-bottom: 64px;

    color: var(--section-heading-color);
  }

  &__careerCard_wrap {
    padding: 26px 0 46px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: rgba(
      33,
      33,
      33,
      1
    );
  }

  &__trackCard_header {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    line-height: 42px;
  }

  &__trackCard_content_wrap {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    line-height: 42px;
  }

  &__trackCard_content_secondaryWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 26px;
  }

  &__trackCard_content_text {
    font-weight: 700;
    font-size: 16px;
    color: var(--hover-color);
    margin: 0 16px;
  }

  &__decor_line_left {
    width: 201px;
    height: 16px;
    display: block;
    color: white;
  }

  &__decor_line_right {
    width: 201px;
    height: 16px;
    display: block;
    color: white;
  }

  &__figure_left {
    width: 428px;
    height: 36px;
    display: block;
    color: white;
  }
  &__figure_right {
    width: 428px;
    height: 36px;
    display: block;
    color: white;
  }

  &__trackCard_btn {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    padding: 12.5px 37px;
    border-radius: 8px;
    color: var(--main-text-color);
    background-color: var(--btn-bgc);
    margin: 0 64px;

    &:active {
      transform: translateY(2px);
    }

    &:hover,
    &:focus {
      background-color: var(
        --hover-color
      );
    }
  }

  &__trackCard_content_textWrap {
    max-width: 551px;
    margin: 0 auto 10px;
  }

  &__trackCard_button_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
  }

  &__trackCard_content_desc {
    font-size: 14px;
    line-height: 24px;
    font-family: var(
      --secondary-font-family
    );
  }

  &__trackCard_content_infoDesc {
    font-size: 14px;
    line-height: 24px;
    color: #9f9f9f;
    font-family: var(
      --secondary-font-family
    );
  }

  &__trackCard_content_periodDesc {
    font-weight: 700;
    font-size: 12px;
    color: var(--accent-color);
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;

    // margin-right: -34px;
    margin-bottom: -34px;
  }

  &__price_num {
    color: var(--hover-color);
    margin-right: 8px;
  }

  &__item {
    padding: 26px 16px 36px 40px;
    max-width: calc(100% / 2 - 34px);
    margin-right: 34px;
    margin-bottom: 34px;

    background: var(--main-bgc);
    border-radius: 10px;
    border: 7px solid var(--main-bgc);

    &:nth-child(2n) {
      margin-right: 0;
    }

    // &:hover,
    // &:focus {
    //   // border: 7px solid
    //   //   var(--hover-color);

    //   .products__btn {
    //     background-color: var(
    //       --hover-color
    //     );
    //   }
    //   // .products__price_num {
    //   //   color: var(--hover-color);
    //   // }
    // }
  }

  &__brHeading {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.4;
    margin-right: 5px;
  }

  &__heading {
    font-weight: 300;
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 44px;
  }

  &__desc {
    font-family: var(
      --secondary-font-family
    );
    font-weight: 400;
    font-size: 16px;
    line-height: 2.2;

    letter-spacing: 0.015em;
  }

  &__purpose {
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 0.005em;
    opacity: 0.6;

    margin: 10px 0;
  }

  &__time {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.7;

    letter-spacing: 0.005em;

    color: var(--accent-color);
  }

  &__line {
    display: block;

    margin: 26px 0 16px;

    opacity: 0.2;
    border: 1px solid #ffffff;
  }

  &__detailedDesc_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;
  }

  &__detailedDesc_secondaryWrap {
    display: flex;
    align-items: center;
  }

  &__detailedDesc_img {
    height: 13px;
    width: 24px;
    fill: #ffffff;

    margin-right: 8px;
  }

  &__detailedDesc {
    font-family: var(
      --secondary-font-family
    );
    word-spacing: 8px;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 0.005em;

    opacity: 0.6;
  }

  &__detailedDesc_text {
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: 0.005em;
    opacity: 0.6;
  }

  &__priseWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__price {
    font-size: 20px;
    line-height: 2;
    letter-spacing: 0.005em;

    margin-right: 20px;
  }

  &__priceDesc {
    font-weight: 300;
    font-size: 16px;
    line-height: 2;
    letter-spacing: 0.005em;
  }

  &__btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;

    color: var(--main-text-color);
    background-color: var(--btn-bgc);

    padding: 12.5px 20px;
    border-radius: 8px;

    &:active {
      transform: translateY(2px);
    }

    &:hover,
    &:focus {
      background-color: var(
        --hover-color
      );
    }
  }

  &__btn_ut {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;

    color: "#212121";
    background-color: #f2f2f2;

    padding: 12.5px 20px;
    border-radius: 8px;
  }

  @media (max-width: 1210px) {
    &__figure_right {
      display: none;
    }

    &__trackCard_button_wrap {
      flex-direction: column;
    }

    &__trackCard_btn {
      margin: 30px 64px 0;
    }
  }
  @media (max-width: 1000px) {
    &__heading {
      font-size: 22px;
      margin-bottom: 25px;
    }
  }

  @media (max-width: 715px) {
    &__decor_line_right {
      display: none;
    }

    &__decor_line_left {
      margin-bottom: 15px;
    }

    &__trackCard_content_secondaryWrap {
      flex-direction: column;

      margin: 20px auto 26px;
    }

    &__careerCard_wrap {
      padding: 26px 15px 46px;
    }
  }

  @media (max-width: 850px) {
    &__item {
      &:nth-child(2n) {
        margin-right: auto;
      }
    }

    &__careerCard_wrap {
      max-width: 95%;
      margin: 0 auto 20px;
    }

    &__heading {
      font-size: 30px;
      margin-bottom: 44px;
    }

    &__item {
      max-width: 95%;
      padding: 26px 16px 36px;

      margin: 0 auto 35px;

      &:last-child() {
        margin: 0 auto;
      }
    }

    &__header {
      text-align: center;
    }
  }
  @media (max-width: 500px) {
    &__list {
      margin-bottom: 0;
    }
    &__item {
      max-width: 100%;
    }
    &__priseWrap {
      display: flex;
      align-items: center;
      /* justify-content: flex-end; */
      flex-direction: column;
    }

    &__price {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__figure_left {
      display: none;
    }

    &__trackCard_btn {
      margin: 0;
    }

    &__careerCard_wrap {
      max-width: 100%;
    }
  }
}

.modal {
  &__checkBoxWrap {
    display: flex;
    flex-direction: row-reverse;
    color: #000;
    font-weight: 700;
    align-items: flex-start;
  }
  &__checkBoxErrWrap {
    display: flex;
    flex-direction: row-reverse;
    color: red;
    font-weight: 700;
    align-items: flex-start;
  }

  &__headerWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__headerClose_icon {
    cursor: pointer;
  }

  &__wrap {
    padding: 25px;
    margin-top: 50px;

    width: 481px;
    background: var(--modal-bgc);
    border-radius: 10px;
  }

  &__header {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;

    color: var(--modal-heading-color);
  }

  &__form {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 32px;
  }

  &__form_label {
    position: relative;
    font-size: 18px;
    line-height: 1.9;
    margin-bottom: 15px;

    color: var(--modal-heading-color);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__form_whatsApp_error {
    position: absolute;
    bottom: -29px;
    color: red;
  }

  &__form_input {
    width: 100%;
    height: 40px;
    padding: 11px 13px;

    border-radius: 4px;

    &::placeholder {
      font-size: 16px;
      line-height: 1.9;
    }
  }

  &__form_btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 12.5px 0;
    margin-top: 42px;

    font-weight: 500;
    font-size: 16px;
    line-height: 1.9;
    color: var(--main-text-color);

    border-radius: 8px;
    background-color: var(--btn-bgc);

    &:active {
      background-color: #5b3bdd;
      transform: translateY(2px);
    }

    &:hover,
    &:focus {
      background-color: var(--hover-color);
    }
  }

  @media (max-width: 520px) {
    &__wrap {
      padding: 25px;
      margin-top: 80px;

      width: 350px;
    }

    &__form_btn {
      margin-top: 30px;
    }

    &__headerWrap {
      margin-bottom: 25px;
    }

    &__header {
      font-size: 21px;
    }
  }

  @media (max-width: 380px) {
    &__wrap {
      width: 300px;
    }

    &__headerWrap {
      margin-bottom: 15px;
    }

    &__form_btn {
      margin-top: 20px;
    }

    &__form_label {
      margin-bottom: 10px;
    }
  }
}

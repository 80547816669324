.main__wrapper {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-weight: 700;
      font-size: 40px;
      min-width: 170px;

      color: var(--section-heading-color);
    }
    .text__mobile {
      display: none;
    }
    .line {
      width: 100%;
      margin: 0 20px 0;

      height: 17px;
      display: block;
    }
    .toggleArrow__wrap {
      width: 36px;
      height: 36px;
      padding: 5px;

      display: flex;
      align-items: center;
      justify-content: center;

      background: var(--accent-color);
      border-radius: 2px;
      cursor: pointer;
    }
    .arrow {
      fill: #ffffff;
    }
  }

  @media (max-width: 850px) {
    .wrapper {
      display: none;
    }
    .text__mobile {
      font-weight: 700;
      font-size: 40px;
      min-width: 170px;
      text-align: center;

      color: var(--section-heading-color);
    }
  }
}

.wrap {
  // margin-top: 65px;
  padding: 190px 0 60px;
  background-color: var(--main-bgc);

  .introImg {
    width: 272px;
    height: 259px;

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }
  }

  .heading {
    font-weight: 700;
    font-size: 45px;
    line-height: 1.4;

    display: flex;
    align-items: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &__wrap {
      margin-bottom: 55px;
      margin-top: 60px;
    }

    &__decor_firstLine {
      width: 145px;
      height: 16px;
      display: block;
    }

    &__decor_thirdLine {
      width: 145px;
      height: 16px;
      display: block;
      margin: 0 15px 0 209px;
    }

    &__secondary_wrap {
      display: flex;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__flex_baseline,
    &__flex_thirdBaseline {
      display: flex;
      align-items: baseline;
    }

    &__first_img {
      margin: 0 14px 0 16px;
    }

    &__second_img {
      margin: 0 14px 0 129px;
    }

    &__third_img {
      margin: 0 0 0 14px;
    }

    &__btn {
      display: none;
    }
  }

  .arrow__down span {
    display: block;
    width: 16px;
    height: 16px;
    border-bottom: 2px solid var(--accent-color);
    border-right: 2px solid var(--accent-color);
    transform: rotate(45deg);
    margin-top: -16px;
    margin-left: 4px;
    animation: arrow-down 2s infinite;
  }
  .arrow__down span:nth-child(2) {
    animation-delay: -0.3s;
  }
  .arrow__down span:nth-child(3) {
    animation-delay: -0.3s;
  }

  .socialIcon {
    &__list {
      position: absolute;
      right: 0;
      list-style: none;

      margin-top: 60px;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      width: 38px;
      margin-bottom: 30px;

      background: #333333;
      border-radius: 50%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1050px) {
    .heading {
      font-size: 40px;
    }
  }

  @media (max-width: 1000px) {
    padding: 130px 0 33px;

    .heading {
      &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__secondary_wrap {
        align-items: center;
      }

      &__first_img {
        margin: 0 0 0 14px;
        height: 40px;
      }

      &__second_img {
        margin: 0 14px 0 0;
        height: 40px;
      }

      &__third_img {
        margin: 0 0 0 14px;
        height: 40px;
      }

      &__decor_firstLine,
      &__decor_thirdLine {
        display: none;
      }

      &__decor_line {
        width: 145px;
        height: 16px;
        display: block;
        margin-top: 10px;
      }

      &__btn {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        padding: 17.5px 26.5px;
        margin-bottom: 50px;

        border-radius: 8px;
        color: var(--main-text-color);
        background-color: var(--btn-bgc);

        &:active {
          background-color: #5b3bdd;
          transform: translateY(2px);
        }
      }
    }
  }

  @media (max-width: 650px) {
    .socialIcon__list {
      top: 108px;
    }

    .introImg {
      display: none;
    }

    .heading {
      font-size: 26px;

      &__wrap {
        margin-bottom: 45px;
      }
    }
  }

  @media (max-width: 544px) {
    .socialIcon__list {
      display: none;
    }

    .arrow__down {
      display: none;
    }
    .heading {
      font-size: 20px;

      &__first_img {
        margin: 0 0 0 7px;
        height: 34px;
      }

      &__second_img {
        margin: 0 7px 0 0;
        height: 34px;
      }

      &__third_img {
        margin: 0 0 0 7px;
        height: 34px;
      }
      &__wrap {
        margin-top: 70px;
      }
    }
  }
  @media (max-width: 350px) {
    .heading {
      font-size: 18px;
    }
  }
}

@keyframes arrow-down {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 451px;
  padding: 25px;
  margin-top: 100px;

  background: var(--modal-bgc);
  border-radius: 10px;
}
.heading {
  font-weight: 500;
  font-size: 24px;
  line-height: 0.75;

  color: var(--modal-heading-color);
}
.img {
  margin: 28px 0;
}
.desc {
  font-size: 16px;
  line-height: 1.2;

  color: var(--modal-heading-color);
}
.btn {
  margin-top: 42px;
  padding: 12.5px 180px;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  color: var(--main-text-color);
  background: var(--btn-bgc);
  border-radius: 8px;
}

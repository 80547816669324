.overlay {
  position: fixed;
  // position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
}

.content {
  min-width: 300px;
  max-width: 700px;
  position: relative;
  height: 100vh;
}

.questions {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.2;

  color: var(--section-heading-color);

  &__section {
    padding: 80px 0 30px;
  }

  &__list {
    margin-top: 75px;
    counter-reset: my-counter 0;
    list-style-type: none;
  }

  &__item_mainWrap {
    display: flex;
    align-items: center;
    margin-left: 67px;
  }

  &__item {
    padding: 40px 0;
    border-bottom: 3px solid
      rgba(205, 214, 218, 0.25);

    &:before {
      position: absolute;
      counter-increment: my-counter;
      content: counter(
        my-counter,
        decimal-leading-zero
      );
      margin-right: 32px;

      font-weight: 700;
      font-size: 32px;
      line-height: 1.2;

      color: rgba(60, 60, 67, 0.5);
    }
  }

  &__svgWrap {
    width: 40px;
    height: 40px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f3f5f6;
    border-radius: 24px;
  }
  @media (max-width: 850px) {
    &__list {
      margin-top: 0;
    }
  }

  @media (max-width: 820px) {
    font-size: 24px;
    // max-width: 375px;

    &__item_mainWrap {
      margin-left: 50px;
    }
  }

  @media (max-width: 500px) {
    max-width: 285px;

    &__section {
      padding: 15px 0 35px;
    }
  }

  @media (max-width: 400px) {
    max-width: 200px;
  }
}

.answer {
  font-size: 18px;
  line-height: 1.4;

  color: rgba(60, 60, 67, 0.85);

  &__list {
    counter-reset: my-second-counter;
    list-style: none;
    margin: 26px 0 0 70px;
  }

  &__item {
    display: flex;
    align-items: center;
    counter-increment: my-second-counter;
    position: relative;

    &:before {
      content: counter(
          my-second-counter
        )
        ".";
      position: absolute;
      top: 0;
      left: -25px;

      font-size: 18px;
      line-height: 1.4;

      color: rgba(60, 60, 67, 0.85);
    }

    &:only-child:before {
      content: "";
    }
  }
}

.section__reviews {
  padding: 80px 0;
  .carousel__wrapper {
    margin-top: 64px;
  }
  .splide {
    display: flex;
    margin-right: 24px;
    overflow: hidden;

    &__wrap {
      margin-top: 70px;
    }

    &__item {
      display: flex;
    }
    &__img {
      width: 180px;
      height: 186px;

      border-radius: 10px;
    }

    &__body {
      display: block;
      max-width: 367px;
      margin-left: 18px;
    }

    &__name {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 10px;

      color: var(--reviews-text-color);
    }

    &__placeWrap {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__flagImg {
      width: 40px;
      height: 36px;
    }

    &__placeName {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.6;
      margin-left: 14px;

      color: var(--reviews-text-color);
    }

    &__desc {
      font-weight: 400;
      font-size: 16px;

      color: var(--reviews-text-color);
    }
  }

  .splide__progress {
    position: relative;
    margin-top: 65px;
    height: 0;
    border: 1px solid #e0e0e0;

    &__bar {
      position: absolute;
      top: -5px;
      width: 238px;
      height: 0px;

      border: 5px solid #714cfe;
      border-radius: 5px;

      -webkit-transition: width 0.4s ease;
      -o-transition: width 0.4s ease;
      transition: width 0.4s ease;
    }
  }
  .showMore__btn {
    font-size: 16px;
    line-height: 1.5;

    color: #714cfe;
  }
  @media (max-width: 1200px) {
    .splide {
      &__desc {
        width: 300px;
      }
    }
  }

  @media (max-width: 1150px) {
    .splide {
      &__item {
        justify-content: center;
      }

      &__body {
        margin-right: 35px;
      }

      &__desc {
        width: 100%;
      }
    }
  }

  @media (max-width: 850px) {
    padding: 34px 0 74px;
  }
  @media (max-width: 674px) {
    .splide {
      &__item {
        flex-direction: column;
        align-items: center;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0;
      }
    }
  }

  @media (max-width: 420px) {
    .splide {
      &__desc {
        font-size: 14px;
        width: 240px;
      }
    }
  }
}

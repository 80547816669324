.footer {
  padding: 74px 0;

  background: var(--main-bgc);
  border-radius: 0px 200px 0px 0px;

  &__wrapper {
    display: flex;
  }

  &__logo {
    width: 130px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__logoDesc {
    font-family: var(
      --secondary-font-family
    );
    font-weight: 300;
    letter-spacing: 0.08em;

    color: var(--accent-color);
  }

  &__heading {
    font-weight: 700;
    font-size: 70px;
    line-height: 1.2;

    margin-top: 54px;

    color: var(--accent-color);
  }

  &__decor_line {
    display: block;
    width: 201px;
    height: 16px;
    margin-top: 44px;
  }

  &__form {
    display: flex;
  }

  &__formHeading {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.9;

    margin-bottom: 24px;
  }

  &__formText_wrap {
    display: flex;
    align-items: center;
  }

  &__formInstruction {
    font-size: 16px;
    line-height: 1.5;
    width: 286px;

    margin: 0 32px 17px 0;
  }

  &__formWrap {
    display: flex;
    flex-direction: column;
  }

  &__formInput {
    width: 286px;
    height: 44px;
    padding: 2px 0px 2px 14px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 2.1;

    color: #ffffff;
    background-color: var(--main-bgc);

    &::placeholder {
      font-size: 16px;
      line-height: 2.1;

      // color: #ffffff;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__formWhatApp_wrap_error {
    position: absolute;
    bottom: 44px;
    color: red;
  }

  &__formError {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  &__errorIcon {
    margin-right: 5px;
  }

  &__form_checkboxWrap {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    position: relative;
  }

  &__form_checkboxInput {
    visibility: hidden;
  }

  &__form_checkboxHeading {
    width: 233px;

    font-size: 16px;
    line-height: 1.5;

    margin-bottom: 14px;
  }

  &__form_item {
    font-size: 16px;
    line-height: 2;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__formBtn {
    width: 286px;
    padding: 12px 96.5px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    // margin-top: 20px;

    background: var(--btn-bgc);
    color: var(--main-text-color);
    border-radius: 3px;

    &:active {
      background-color: #5b3bdd;
      transform: translateY(2px);
    }

    &:hover,
    &:focus {
      background-color: var(
        --hover-color
      );
    }
  }

  &__navList {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 130px 0 34px;
  }

  &__navItem {
    position: relative;
    margin-right: 48px;

    &:last-child {
      margin-right: 0;
    }

    flex-shrink: 0;
    a {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        opacity: 0;
        top: 54px;
        left: 0;
        // left: -53px;
        width: 100%;
        height: 5px;
        background-color: var(
          --accent-color
        );
        border-radius: 12.5px;
        transform: translateX(-100%);
        transition: all ease 0.5s;
      }
    }
    &:hover {
      a {
        &::after {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &__navLink {
    font-size: 18px;
    line-height: 0.8;
    position: relative;

    color: var(--main-text-color);
    text-decoration: none;
    flex-shrink: 0;
  }

  &__descHr_bottom {
    border: 1px solid #ffffff;
  }
  &__descHr_top {
    display: none;
  }
  input,
  input:hover,
  textarea,
  textarea:hover {
    outline: none;
  }
  // input[type="checkbox"] {
  //   visibility: hidden;
  // }
  label.footer__form_item {
    user-select: none;
    position: relative;
    cursor: pointer;
    line-height: 36px;
    padding-left: 40px;
  }
  label.footer__form_item
    input:checked
    ~ .geekmark:after {
    opacity: 1;
  }
  label.footer__form_item
    input:checked
    ~ .geekmark {
    border: 1px solid #fff;
  }
  label.footer__form_item
    .geekmark:after {
    left: 8px;
    bottom: 6px;
    width: 6px;
    height: 12px;
    border: solid var(--hover-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .geekmark {
    position: absolute;
    top: 5px;
    left: 15px;
    height: 24px;
    width: 24px;
    border-radius: 2px;
    background-color: transparent;
    transition: all ease 0.3s;
    &:hover {
      border: 1px solid
        var(--hover-color);
    }
    &::after {
      content: "";
      position: absolute;
      opacity: 0;
    }
  }

  .footer__bottom_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
  .socialIcon__list {
    display: flex;
    list-style: none;
  }

  .socialIcon__item {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 38px;
    width: 38px;
    margin-right: 55px;

    background: #333333;
    border-radius: 50%;

    &:last-child {
      margin-right: 0px;
    }
  }

  .basement {
    display: flex;
    align-items: center;

    &__link {
      font-size: 18px;
      line-height: 0.8;

      margin-right: 48px;

      color: var(--main-text-color);

      &:hover,
      &:focus {
        color: var(--accent-color);
      }
    }

    &__copyright {
      font-size: 18px;
      line-height: 0.8;

      margin-right: 48px;

      color: var(--main-text-color);
    }
  }

  .track__error {
    color: red;
    margin-left: 15px;
  }

  @media (max-width: 1100px) {
    &__heading {
      font-size: 55px;
    }
  }
  @media (max-width: 995px) {
    padding: 40px 0;
    border-radius: 0;

    &__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__logoWrap {
      display: flex;
      align-items: flex-end;
      margin-bottom: 40px;
    }

    &__decor_line {
      display: none;
    }

    &__heading {
      margin-left: 76px;
    }

    &__navList {
      justify-content: center;
      margin: 80px 0 34px;
    }

    .socialIcon__item {
      margin-right: 35px;
    }

    .basement {
      &__link {
        margin-right: 35px;
      }
    }
  }

  @media (max-width: 800px) {
    &__heading {
      margin-left: 30px;
      font-size: 50px;
    }
  }

  @media (max-width: 705px) {
    &__heading {
      margin-left: 0;
      margin-top: 0;
      font-size: 28px;
    }

    &__form {
      flex-direction: column;
    }

    &__logo {
      display: none;
    }

    &__logoWrap {
      margin-bottom: 15px;
    }

    &__form_checkboxHeading {
      display: none;
    }

    &__form_checkboxWrap {
      margin-left: 0px;
      margin-top: 10px;
    }

    &__navList {
      flex-direction: column;
      margin: 44px 0;
    }

    &__navItem {
      margin: 0 0 44px;

      a {
        position: static;
        &::after {
          display: none;
        }
        &:hover,
        &:focus {
          color: var(--accent-color);
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    &__descHr_bottom {
      display: none;
    }

    &__descHr_top {
      display: block;
      margin-top: 34px;
      border: 1px solid #ffffff;
    }

    &__formWrap {
      align-items: center;
    }

    &__formInstruction {
      margin: 0 0 17px 0;
    }

    &__formHeading {
      margin-right: 200px;
    }

    .basement {
      flex-direction: column;

      &__link {
        margin: 0 0 44px 0;
      }

      &__copyright {
        margin: 0 0 44px 0;
      }
    }

    .socialIcon__item {
      margin-right: 65px;
    }

    .footer__bottom_wrap {
      margin-top: 0;
      flex-direction: column-reverse;
    }
  }
}

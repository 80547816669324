.aboutUs {
  &__section {
    padding: 80px 0;
  }

  &__wrap {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    max-width: 445px;
    font-size: 16px;
    line-height: 1.5;
    margin-right: 90px;

    color: var(--secondary-text-color);
  }

  &__descWrap_main {
    display: flex;
  }

  &__descWrap {
    max-width: 140px;
    margin-right: 60px;

    text-align: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__value {
    font-weight: 700;
    font-size: 50px;
    line-height: 1.14;

    color: var(--section-heading-color);
  }

  &__line {
    width: 139px;
    background-position: center;
    display: block;
    height: 6px;
    border: 1px solid #212121;
    border-radius: 4px;
  }

  &__desc {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.15;

    margin-top: 17px;

    color: var(--section-heading-color);
  }

  @media (max-width: 1000px) {
    &__text {
      font-size: 14;
      margin-right: 60px;
    }

    &__value {
      font-size: 40px;
    }

    &__desc {
      font-size: 17px;
    }

    &__line {
      width: 70px;
    }
  }

  @media (max-width: 850px) {
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__heading {
      font-weight: 700;
      font-size: 35px;
      line-height: 1;
      margin-bottom: 24px;

      text-align: center;

      color: #333333;
    }

    &__descWrap_main {
      margin-top: 34px;
    }
    &__section {
      padding: 34px 0;
    }

    &__text {
      margin-right: 0;
      text-align: center;
    }
  }

  @media (max-width: 515px) {
    &__value {
      font-size: 25px;
    }
    &__text {
      font-size: 14px;
      max-width: 368px;
    }

    &__desc {
      font-size: 10px;
    }
    &__descWrap {
      margin-right: 55px;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans:wght@400;500;700&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --main-font-family: "Roboto",
    "Open Sans";
  --secondary-font-family: "Lato",
    "Open Sans";

  --main-bgc: #212121;
  --accent-color: #00dc97;
  --main-text-color: #fff;
  --secondary-text-color: #333333;
  --btn-bgc: #714cfe;
  --section-heading-color: #000000;
  --modal-heading-color: #000000;
  --reviews-text-color: #212121;
  --hover-color: #fe704c;
  --modal-bgc: #ffffff;
}

body {
  font-family: var(--main-font-family);
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.08em;
  margin: 0;

  color: var(--main-text-color);
}

*,
::before,
::after {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

img {
  display: block;
  // width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

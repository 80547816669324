.section {
  padding: 70px 0 50px;

  border-radius: 200px 0px 0px 0px;

  background: var(--main-bgc);

  .heading {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.8;
    text-align: center;
  }
  .desc {
    &__list {
      display: flex;
      flex-wrap: nowrap;
      list-style: none;
    }

    &__item {
      // max-width: calc(100% / 4 - 26px);
      width: 293px;
      padding: 20px 16px;
      margin-right: 26px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__decor_line {
      max-width: 201px;
      height: 16px;
      display: block;

      margin-bottom: 16px;
    }
  }
  .header {
    font-weight: 500;
    font-size: 22px;

    margin: 16px 0;
  }
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  .request__btn {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;

    padding: 17.5px 44px;
    margin: 48px auto 0;
    border-radius: 8px;

    color: var(--main-text-color);
    background-color: var(--btn-bgc);

    &:active {
      background-color: #5b3bdd;
      transform: translateY(2px);
    }

    &:hover,
    &:focus {
      background-color: var(
        --hover-color
      );
    }
  }

  @media (max-width: 990px) {
    border-radius: 0px 0px 0px 0px;
    .desc {
      &__list {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
      }

      &__item {
        width: calc(100% / 2 - 100px);
        margin-right: 0;
      }
    }
  }
  @media (max-width: 670px) {
    padding: 44px 0 20px;

    .heading {
      font-size: 35px;

      text-align: start;
    }

    .desc {
      &__list {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      &__decor_line {
        display: none;
      }

      &__item {
        width: 100%;
      }
    }
  }
}
